import { useRouter } from "next/navigation";
import { useEffect } from "react";

const Error404 = () => {
  const router = useRouter();
  useEffect(() => {
    router.push("/");
  }, [router]);
  return <div>not found</div>;
};

export default Error404;
